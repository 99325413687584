import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'
import htmlSerializer from '../../utils/htmlSerializer'
import '../../components/layout.css'

export default ({ slice }) => (
  <div className="post-text container">
    <div className="mt-8 mb-4">
      {/* <div className="mt-8 h-full max-h-full border-red-600 border p-4"> */}
      {RichText.render(slice.primary.custom_rich_text, linkResolver, htmlSerializer)}
    </div>
    {/* <img src={slice.primary.image.url} alt={slice.primary.image.alt} /> */}
  </div>
)
