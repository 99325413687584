import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layout'
import { Custom } from '../components/slices'
import goBackArrow from '../assets/baby-pack/go_back.svg'

// Query for the Blog Post content in Prismic
export const query = graphql`
  query BlogPostQuery($uid: String) {
    prismic {
      allBlog_postss(uid: $uid) {
        edges {
          node {
            blog_title
            blog_header_image
            _linkType
            _meta {
              uid
              id
              type
            }
            blog_section
            blog_date
            body {
              ... on PRISMIC_Blog_postsBodyCustom {
                type
                label
                primary {
                  custom_rich_text
                  custom_highlight
                  custom_image
                  custom_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// Sort and display the different slice options
const PostSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.type) {
        case 'custom':
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<Custom slice={slice} />}
            </div>
          )
        // case 'image':
        //   return (
        //     <div key={index} className="homepage-slice-wrapper">
        //       {<ImageCaption slice={slice} />}
        //     </div>
        //   )

        // case 'quote': return (
        //   <div key={ index } className="homepage-slice-wrapper">
        //     { <Quote slice={ slice } /> }
        //   </div>
        // )

        // case 'image_with_caption': return (
        //   <div key={ index } className="homepage-slice-wrapper">
        //     { <ImageCaption slice={ slice } /> }
        //   </div>
        // )

        default:
          return
      }
    })()
    return res
  })
}

// Display the title, date, and content of the Post
const PostBody = ({ blogPost }) => {
  const titled = blogPost.blog_title.length !== 0
  return (
    <div>
      <div className="back pt-6 pl-6">
        <button
          onClick={() => {
            typeof history !== 'undefined' && history.go(-1)
          }}
          className="flex align-middle"
        >
          <img src={goBackArrow} className="h-8 inline mr-2" />
          <span className="text-2xl text-gray-700">Revenir aux articles</span>
        </button>
      </div>
      <section className="post-summary mt-8 mx-auto text-left max-w-3xl px-4">
        <div className="container post-header">
          {/* Render the edit button */}
          <h1
            className="text-5xl md:text-4xl text-center text-custom-purple font-header font-bold"
            data-wio-id={blogPost._meta.id}
          >
            {titled ? RichText.asText(blogPost.blog_title) : 'Untitled'}
          </h1>
          <img
            className="rounded-xl mx-auto my-4"
            src={blogPost.blog_header_image.url}
            alt={blogPost.blog_header_image.alt}
            title={blogPost.blog_header_image.copyright}
          />
        </div>
        {/* Go through the slices of the post and render the appropiate one */}
        <PostSlices className="post-body text-left" slices={blogPost.body} />
        <div>
          {/* custom_rich_text
                  custom_highlight
                  custom_image
                  custom_link */}
          <img
            src={blogPost?.body?.[0]?.primary?.custom_image?.url}
            alt={blogPost?.body?.[0]?.primary?.custom_image?.alt}
          />
          {blogPost?.body?.[0]?.primary?.custom_highlight?.[0]?.text && (
            <div className="p-4 my-8 border-custom-purple border-4 border-current">
              {blogPost?.body?.[0]?.primary?.custom_highlight?.[0]?.text}
            </div>
          )}
          {blogPost?.body?.[0]?.primary?.custom_link?.url && (
            <a className="underline border-blue-700" href={blogPost?.body?.[0]?.primary?.custom_link?.url}>
              {blogPost?.body?.[0]?.primary?.custom_link?.url.replace(/https?:\/\//, '')}
            </a>
          )}
        </div>
      </section>
    </div>
  )
}

export default (props) => {
  // console.log('props', props.data.prismic.allBlog_postss.edges)
  // Define the Post content returned from Prismic
  const doc = props.data.prismic.allBlog_postss.edges.slice(0, 1).pop()
  // console.log('doc', doc)

  if (!doc) return null
  // console.log('post', doc.node)

  return (
    <Layout>
      <PostBody blogPost={doc.node} />
    </Layout>
  )
}
